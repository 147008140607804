import service from '@/services'

// Module Vuex kas
export default {
  namespaced: true,
  state: {
    datas: [],
    currentKas: null,
    kasOptions: [],
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data
    },
    SET_DATA_TOTALS(state, data) {
      state.totals = data
    },
    SET_CURRENT_KAS(state, data) {
      state.currentKas = data
    },
    SET_KAS_OPTION(state, data) {
      state.kasOptions = data
    },
  },
  actions: {
    async getData({ commit }, params = {}) {
      try {
        const config = {
          url: '/order/ginee-order-list',
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: 'get',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        const result = response;
        const result_total = response.data.total;
        commit("SET_DATA", result);
        // commit("SET_DATA_TOTALS", result_total);

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getChannel({ commit }, params = {}) {
      try {
        const config = {
          url: '/ginee/channel-list',
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: 'get',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        const result = response;
        const result_total = response.data.total;
        // commit('SET_DATA', result)

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getLogistic({ commit }, params = {}) {
      try {
        const config = {
          url: '/ginee/logistic-list',
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: 'get',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        const result = response;
        const result_total = response.data.total;
        // commit('SET_DATA', result)

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getShip({ commit }, params = {}) {
      try {
        const config = {
          url: '/ginee/shipping-params',
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: 'get',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        const result = response;
        const result_total = response.data.total;
        // commit('SET_DATA', result)

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getShop({ commit }, params = {}) {
      try {
        const config = {
          url: '/ginee/shop-list',
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: 'get',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        const result = response;
        const result_total = response.data.total;
        // commit('SET_DATA', result)

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getStatusOrder({ commit }, params = {}) {
      try {
        const config = {
          url: '/ginee/order-status-list',
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: 'get',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        const result = response;
        const result_total = response.data.total;
        // commit('SET_DATA', result)

        return Promise.resolve(result)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async sync({}, payload = {}) {
      try {
        const config = {
          url: '/sync/ginee-orders',
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async ceksync({ commit }, params = {}) {
      try {
        const config = {
          url: '/sync/tracking-progress',
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: 'get',
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)
        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async print({}, payload = {}) {
      try {
        const config = {
          url: '/order/print-ginee-document',
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getDataById({}, id) {
      try {
        const config = {
          url: `/kas/${id}`,
          method: 'get',
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }
        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async save({}, payload = {}) {
      try {
        const config = {
          url: '/input/kas',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async mutasiKas({}, payload) {
      try {
        const config = {
          url: '/kas-mutasi',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async saveJurnalKas({}, payload = {}) {
      try {
        const config = {
          url: '/input/jurnal',
          method: 'post',
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        }

        const response = await service.sendRequest(config)

        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
}
